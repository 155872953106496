import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { SocialChannelButtonsComponent } from './components/social-channel-buttons/social-channel-buttons.component';
import { CoreModule } from '../core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
import { IconModule } from '@teamfoster/sdk/icon';

@NgModule({
  declarations: [SocialChannelButtonsComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
    TextUtilityModule,
    DictionaryNgrxModule,
    CoreModule,
  ],
  exports: [SocialChannelButtonsComponent],
})
export class SocialMediaModule {}
