import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
import { IconModule } from '@teamfoster/sdk/icon';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
    TextUtilityModule,
  ],
})
export class PrincipleModule {}
