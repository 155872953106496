// Env
import { environment } from '../environments/environment';
// Angular
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
// Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './404.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Foster Modules
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
// Locale
import localeNl from './nl';
import { IMAGE_CONFIG, registerLocaleData } from '@angular/common';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SocialMediaModule } from './social-media/social-media.module';
import { ContentModule } from './content/content.module';
import { PrincipleModule } from './principle/principles.module';
import { ImageFlowConfig, ImageSharpConfig, ProvideImageProcessor, ProvideOptimisedImageProcessor } from '@teamfoster/sdk/image';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './customroute-strategy';

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-HEZS6J4D16',
  strippedContentPlaceholder: 'This content will not be shown because you have not accepted marketing cookies',
  buttonSettings: 'Settings',
  buttonAcceptAll: 'Accept all',
  buttonClose: 'Close',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

registerLocaleData(localeNl, 'en');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Foster Modules
    DictionaryNgrxModule.forRoot({
      // apiUrl: 'http://localhost:5000/api/dictionary',
    }),
    MenuNgrxModule.forRoot({
      // apiUrl: 'http://localhost:5000/api/menu',
    }),
    SocialMediaModule,
    PrincipleModule,
    ContentModule,
    CookieNgrxModule.forRoot(cookieConfig),
    IconModule.forRoot({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
  ],
  providers: [
    // { provide: IMAGE_CONFIG, useValue: ImageFlowConfig },
    //ProvideImageProcessor(ImageFlowConfig),
    ProvideOptimisedImageProcessor(ImageFlowConfig, {}),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: LOCALE_ID, useValue: 'en' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
