import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { SocialChannelsGuard } from './social-media/guards';

const appGuards = [DictionaryGuard, MenuGuard, CookieSettingsGuard, SocialChannelsGuard];

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleGuideModule),
    canActivate: [],
  },
  {
    path: 'page',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
    data: {
      contentTypeId: 1,
    },
  },
  {
    path: 'news',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
    data: {
      contentTypeId: 2,
    },
  },
  {
    path: 'vacancies',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
    data: {
      contentTypeId: 5,
    },
  },
  {
    path: 'team',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
    data: {
      contentTypeId: 4,
    },
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
    canActivate: [...appGuards],
  },

  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
